<template>
  <b-card
    class="financepaymentitem-edit-wrapper"
  >
    <!-- form -->
    <b-form id="financepaymentitemForm" class="edit-form mt-2">
      <b-row>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="结算方式 f_settlement_method"
            label-for="f_settlement_method"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="f_settlement_method"
              size="sm"
              v-model="financepaymentitem.f_settlement_method"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="付款用途 f_payment_purpose"
            label-for="payment_purpose"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="payment_purpose"
              size="sm"
              v-model="financepaymentitem.payment_purpose"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="预付项目类型 f_ prepay_project_type"
            label-for="prepay_project_type"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="prepay_project_type"
              size="sm"
              v-model="financepaymentitem.prepay_project_type"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="应付金额"
            label-for="payable_amount"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="payable_amount"
              size="sm"
              v-model="financepaymentitem.payable_amount"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="现金折扣"
            label-for="cash_discount"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="cash_discount"
              size="sm"
              v-model="financepaymentitem.cash_discount"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="折后金额"
            label-for="after_discount_amount"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="after_discount_amount"
              size="sm"
              v-model="financepaymentitem.after_discount_amount"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="长短款"
            label-for="long_short"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="long_short"
              size="sm"
              v-model="financepaymentitem.long_short"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="手续费"
            label-for="service_charge"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="service_charge"
              size="sm"
              v-model="financepaymentitem.service_charge"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="实付金额"
            label-for="paid_amount"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="paid_amount"
              size="sm"
              v-model="financepaymentitem.paid_amount"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="采购订单ID"
            label-for="order_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="order_id"
              size="sm"
              v-model="financepaymentitem.order_id"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="采购订单号"
            label-for="order_no"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="order_no"
              size="sm"
              v-model="financepaymentitem.order_no"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="我方银行账号"
            label-for="our_bank_account"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="our_bank_account"
              size="sm"
              v-model="financepaymentitem.our_bank_account"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="我方账户名吃"
            label-for="our_bank_name"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="our_bank_name"
              size="sm"
              v-model="financepaymentitem.our_bank_name"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="我方开户行"
            label-for="our_opening_bank"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="our_opening_bank"
              size="sm"
              v-model="financepaymentitem.our_opening_bank"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="内部账号"
            label-for="inner_account"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="inner_account"
              size="sm"
              v-model="financepaymentitem.inner_account"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="内部账号名称"
            label-for="inner_account_name"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="inner_account_name"
              size="sm"
              v-model="financepaymentitem.inner_account_name"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="对方银行账号"
            label-for="other_bank_account"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="other_bank_account"
              size="sm"
              v-model="financepaymentitem.other_bank_account"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="对方账户名吃"
            label-for="other_bank_name"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="other_bank_name"
              size="sm"
              v-model="financepaymentitem.other_bank_name"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="开户行地址"
            label-for="opening_bank_address"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="opening_bank_address"
              size="sm"
              v-model="financepaymentitem.opening_bank_address"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="备注"
            label-for="remark"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="remark"
              size="sm"
              v-model="financepaymentitem.remark"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="状态"
            label-for="state"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="state"
              size="sm"
              v-model="financepaymentitem.state"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="添加时间"
            label-for="create_time"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="create_time"
              size="sm"
              v-model="financepaymentitem.create_time"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="添加人ID"
            label-for="creator"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="creator"
              size="sm"
              v-model="financepaymentitem.creator"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="更新时间"
            label-for="update_time"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="update_time"
              size="sm"
              v-model="financepaymentitem.update_time"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="更新人ID"
            label-for="updator"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="updator"
              size="sm"
              v-model="financepaymentitem.updator"
            />
          </b-form-group>
        </b-col>
                <b-col
          cols="12"
          class="mt-50"
        >
          <b-button
            variant="primary"
            class="mr-1"
            @click="save"
          >
            保存
          </b-button>
          <b-button
            variant="outline-secondary"
            @click="cancel"
          >
            取消
          </b-button>
        </b-col>
      </b-row>
    </b-form>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard, BMedia, BAvatar, BCardText, BForm, BRow, BCol, BFormGroup, BFormInput, BImg, BFormFile, BLink, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {onUnmounted, ref} from '@vue/composition-api'
import router from "@/router";
import store from "@/store";
import financepaymentitemStore from './financepaymentitemStore'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor } from '@core/utils/filter'

export default {
  components: {
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    useToast,
  },
  data() {
    return {
      id: ref(0),
      financepaymentitem: ref({}),
    }
  },
  setup() {
    const toast = useToast()

    // Register module
    if (!store.hasModule('financepaymentitem')) store.registerModule('financepaymentitem', financepaymentitemStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('financepaymentitem')) store.unregisterModule('financepaymentitem')
    })

    const edit = function() {
      store.dispatch('financepaymentitem/edit', {id: this.id}).then(res => {
        this.financepaymentitem = res.data.data
      })
    }

    const view = function() {
      store.dispatch('financepaymentitem/view', {id: this.id}).then(res => {
        this.financepaymentitem = res.data.data
      })
    }

    const cancel = function() {
      this.$router.go(-1)
    }

    const save = function() {
      store.dispatch('financepaymentitem/save', this.financepaymentitem).then(res => {
        toast.success('数据已保存!')
        this.$router.push({ name: 'apps-${classVar}-list'});
      })
    }

    return {
      edit,
      view,
      cancel,
      save,

      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
    }
  },
  created() {
    this.id = this.$route.query.id || 0;
    this.edit()
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
